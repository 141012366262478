import { createStyles, makeStyles } from "@mui/styles";
import type { FC } from "react";

const useStyles = makeStyles(() =>
  createStyles({
    "@global": {
      "*": {
        boxSizing: "border-box",
        margin: 0,
        padding: 0,
      },
      html: {
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
        height: "100%",
        width: "100%",
        scrollBehavior: "smooth",
        fontFamily: "'Roboto', sans-serif",
      },
      body: {
        height: "100%",
        width: "100%",
        fontFamily: "'Roboto', sans-serif",
        background: "#00060A",
      },
      "#root": {
        height: "100%",
        width: "100%",
      },
      a: {
        color: "white",
      },
    },
  })
);

const GlobalStyles: FC = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
